import { Link } from "gatsby";
import React from "react";
import Logo from "../assets/Logomark.svg";
import NavMenu from "../components/NavMenu";
import styles from "./styles.module.scss";

const Header = () => (
  <div className={styles.header}>
    <NavMenu />
    <Link to="/">
      <Logo id={styles.smallLogo} />
    </Link>
  </div>
);

export default Header;
