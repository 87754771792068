import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import seoImage from "../media/seo-image.png";
import useSiteMetadata from "../utilities/seo";

const Layout = (props) => {
  const { siteUrl } = useSiteMetadata();

  const { description, title, children, pathname } = props;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta property="og:url" content={`${siteUrl}${pathname}`}></meta>
        <meta property="og:title" content={title}></meta>
        <meta property="og:description" content={description}></meta>
        <meta property="og:image" content={`${siteUrl}${seoImage}`}></meta>
        <title>{title}</title>
        <link rel="canonical" href={`${siteUrl}${pathname}`} />
      </Helmet>
      <div>{children}</div>
    </div>
  );
};

Layout.defaultProps = {
  title: "mahusai.ph",
  description: "mahusai.ph",
  pathname: "/",
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default Layout;
