import { bool } from "prop-types";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CloseButton from "../assets/icons/x-menu.svg";
import styles from "../styles/nav.module.scss";

const NavMenu = (props) => {
  const [nav, showNav] = useState(false);

  const onClickIcon = () => {
    showNav(!nav);
    const icon = document.getElementById("btn");
    icon.classList.toggle(styles.changeIcon);

    const menu = document.getElementById("menu");
    menu.classList.toggle(styles.toggleMenu);

    const navContainer = document.getElementById("navContainer");
    navContainer.classList.toggle(styles.navContainerOverflow);

    // const closeButton = document.getElementById("closebtn");
    // closeButton.classList.toggle(tyles.showClose);
  };

  return (
    <div>
      <button id="btn" className={styles.hamburgerIcon} onClick={onClickIcon}>
        <div />
        <div />
        <div />
        <CloseButton />
      </button>
      <nav id="menu">
        <Container id="navContainer" fluid className={styles.navContainer}>
          <Row xs={1} md={1} lg={4}>
            <Col xs={{ order: 2 }} md={{ order: 2 }} lg={{ offset: 6 }}>
              <div className={styles.details}>
                <div className={styles.companyName}>MAHUSAI Inc.</div>
                <div className={styles.address}>
                  Level 10-1 One Global Place
                  <br />
                  25th Street &amp; 5th Avenue
                  <br />
                  Bonifacio Global City
                  <br />
                  Taguig City
                  <br />
                  1634 Philippines
                  <br />
                </div>
              </div>
              <div className={styles.contact}>
                PHONE +63232244173
                <br />
                FAX +63232244174
                <br />
                E-MAIL contact@mahusai.ph
              </div>
            </Col>
            <Col xs={{ order: 1 }} md={{ order: 1 }} lg={{ order: "last" }}>
              <Row>
                <Col className={styles.links + " " + styles.firstMenuItem}>
                  {/* <p> */}
                  <a href="/about" className={styles.menuLink}>
                    <p>ABOUT</p>
                  </a>
                  {/* </p> */}
                </Col>
              </Row>
              <Row>
                <Col className={styles.links}>
                  <a href="/services" className={styles.menuLink}>
                    <p>SERVICES</p>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className={styles.links}>
                  <a href="/portfolio" className={styles.menuLink}>
                    <p>PORTFOLIO</p>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className={styles.links}>
                  <a href="/careers" className={styles.menuLink}>
                    <p>CAREERS</p>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className={styles.links}>
                  <a href="/contact" className={styles.menuLink}>
                    <p>CONTACT</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </nav>
      {/* </MenuLinks> */}
    </div>
  );
};

NavMenu.defaultProps = {
  whiteDesktop: false,
};

NavMenu.propTypes = {
  whiteDesktop: bool,
};

export default NavMenu;
